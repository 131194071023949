import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-johobiss',
  templateUrl: './johobiss.component.html',
  styleUrls: ['../app.component.css']
})
export class JohobissComponent implements OnInit {
  playing: string;
  artistMeta: Object= {
    johobiss: ["Disrespectacular"],
    identityfreaks: ["Dark_Tag"],
    je1ku: ["Gimepauz"],
    ketseleh: ["Pickin_clovers"]
  };
  albumMeta: Object= {
    Disrespectacular: {songs:["300spirals.wav","Type II AB.wav","IDC.wav","Bleachyre.wav"],
                    descriptHead:"An EP in two halves:",
                    descriptBody:["a spell to seal away evil","songs about evils that need sealing away"],
                    descriptFeat:"(featuring Sleazy)"},
    Dark_Tag: {songs:["Light Gun.mp3","Memorial Hospital.mp3","Angels (Angles).mp3","satellite noises.mp3","Bonus Stage.mp3"],
                    descriptHead:"",
                    descriptBody:[],
                    descriptFeat:""},
    Gimepauz: {songs:["wilntrupt 1.wav","chugin raise.wav","muriapauz r2.wav","lugia 2.wav"],
                    descriptHead:"An EP",
                    descriptBody:["At least one of these tracks is probably 'Good' or 'chill' i dunno!"],
                    descriptFeat:""},
    Pickin_clovers: {songs:["pickin clovers.mp3","Cave.mp3"],
                    descriptHead:"A short one",
                    descriptBody:["Nostalgia giving!"],
                    descriptFeat:""}     
  };
  breakpoint: number
  gridSize: number
  currentArtist:string;
  artistName: Object={
    johobiss:"Johobiss",
    ketseleh:"Ketseleh",
    identityfreaks:"Identity Freaks",
    je1ku:"Je1ku"
  }
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
    ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params=>{
      this.currentArtist = params.name
    })
    this.breakpoint = (window.innerWidth <= 900) ? 1 : 3;
    this.gridResize(window.innerWidth)
  }
  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 900) ? 1 : 3;
    this.gridResize(event.target.innerWidth)
  }
  gridResize(input:number){
    if (input<=300){
      this.gridSize=500/input
    }else if(input<=900){
      this.gridSize=700/input
    }else if (input<=1200){
      this.gridSize=input/500
    }else{
      this.gridSize=1.3
    }
  }
  startPlay(song:string,album:string) {
    this.playing=song
    for (var i=0; i<this.albumMeta[album].songs.length; i++){
      if (song!=this.albumMeta[album].songs[i]){
        let audioPlayer: HTMLVideoElement =<HTMLVideoElement>document.getElementById(this.albumMeta[album].songs[i])
        audioPlayer['controls'] = false;
        audioPlayer.pause()
      }
      else{
        let audioPlayer: HTMLVideoElement =<HTMLVideoElement>document.getElementById(this.albumMeta[album].songs[i])
        audioPlayer['controls'] = true;
        audioPlayer.play()
      }
    }
  }

}
