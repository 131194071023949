import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {Routes, RouterModule} from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatGridListModule} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxPayPalModule } from 'ngx-paypal';

import { AppComponent } from './app.component';
import { JohobissComponent } from './johobiss/johobiss.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { MailinglistComponent } from './mailinglist/mailinglist.component';

const routes: Routes=[
  {
    path: 'artists/:name',
    component: JohobissComponent,
    data:{title:'artists'}
  },
  {
    path: 'contact',
    component: ContactComponent,
    data:{title:'contact'}
  },
  {
    path: '',
    component: HomeComponent,
    data:{title:'archetype'}
  },
  {
    path: 'mailinglist',
    component: MailinglistComponent
  }
];
@NgModule({
  declarations: [
    AppComponent,
    JohobissComponent,
    ContactComponent,
    HomeComponent,
    MailinglistComponent,
  ],
  imports: [
    FlexLayoutModule,
    NgxPayPalModule,
    BrowserAnimationsModule,
    MatGridListModule,
    RouterModule.forRoot(routes,{onSameUrlNavigation:"reload"}),
    BrowserModule,
    FormsModule,
    HttpClientModule
  ],
  exports:[
    RouterModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
