import { Component, OnInit ,HostListener} from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['../app.component.css'],
  animations: [
    trigger('enterLeave', [
      state('initial', style({
        'max-height': '0%',
        'overflow-y': 'hidden'
      })),
      state('final', style({
        'max-height': '100%'
      })),
      transition('initial=>final', animate('10000ms')),
      transition('final=>initial', animate('900ms'))
    ]),
    trigger('appear', [
      state('initial', style({
        'opacity': '0.0',
      })),
      state('final', style({
        'opacity': '1.0',
      })),
      transition('initial=>final', animate('500ms')),
      transition('final=>initial', animate('500ms'))
    ]),
    trigger('flicker', [
      state('initial', style({
      })),
      state('final', style({
        'filter': 'brightness(3%)',
      })),
      transition('initial=>final', animate('5ms')),
      transition('final=>initial', animate('5ms'))
    ]),
    trigger('spinback', [
      state('initial', style({
      })),
      state('final', style({
        'background-position': 'bottom',
      })),
      transition('initial=>final', animate('20000ms')),
      transition('final=>initial', animate('50ms'))
    ])
  ],
})
export class HomeComponent implements OnInit {
  // @HostListener('window:scroll', ['$event']) onScrollEvent($event){
  //   console.log($event);
  //   console.log("scrolling");
  // } 
  constructor(private router: Router) { }
  breakpoint:number;
  lineState='final'
  titleState=['initial','initial','initial','initial','initial','initial','initial','initial','initial']
  currentState = ['initial','initial','initial','initial']
  backState='initial'
  logoState='initial'
  quotes=["It cannot be a question of cause and effect, but of a falling together in time, a kind of simultaneity.",
          "I must not fear. Fear is the mind-killer. Fear is the little-death that brings total obliteration.",
          "If a mosquito has a soul, it is mostly evil. So I don't have too many qualms about putting a mosquito out of its misery.",
          "I will face my fear. I will permit it to pass over me and through me.",
          "Recursive Acronyms Like 'RALRECIR' Especially Create Infinite Regress",
          "In the meantime bear in mind that all is Life -- Life -- Life within Life -- the less within the greater, and all within the Spirit Divine.",
          "Harmony: the little go and the great come; auspicious; receipt.",
          "Sometimes the only sane response to an insane world is insanity.",
          "What do you despise? By this are you truly known.",
          "If I can't dance, I don't want to be part of your revolution.",
          "What if all the world you think you know is an elaborate dream?",
          "Anywhere can be paradise as long as you have the will to live. After all, you are alive, so you will always have the chance to be happy.",
          "They are wild waves of the sea, foaming up their own shame; wandering stars, for whom blackest darkness has been reserved forever.",
          "In the end, we self-perceiving, self-inventing, locked-in mirages are little miracles of self-reference.",
          "Knowing your own darkness is the best method for dealing with the darknesses of other people.",
          "We cannot change anything until we accept it. Condemnation does not liberate, it oppresses.",
          "The most violent element in society is ignorance.",
          "I triumph. I have stolen the golden secret of the Egyptians. I will indulge my sacred fury.",
          "Nature uses only the longest threads to weave her patterns, so that each small piece of her fabric reveals the organization of the entire tapestry.",
          "When fear has gone past I will turn the inner eye to see its path. Where the fear has gone there will be nothing. Only I will remain.",
          "We should, so we are told, eschew evil and neither touch nor mention it. For evil is also the thing of ill omen, that which is tabooed and feared.",
          "Deep in the human unconscious is a pervasive need for a logical universe that makes sense. But the real universe is always one step beyond logic."
          ]
  barrs=[["1","2","3","4"],
        ["Will","You","Love","?"],
        ["Eat","Sleep","Slave","Repeat"],
        ["Destroy","All","Divisions","Now"]]
  quote=this.getRandom(this.quotes)
  bars=this.getRandom(this.barrs)
  overload=0
  ngOnInit() {
    this.breakpoint = (window.innerWidth <= 900) ? 1 : 9;
  }
  async delay(ms: number) {
    await new Promise(resolve => setTimeout(()=>resolve(), ms)).then(()=>{});
  }
  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 900) ? 1 : 9;
  }
  getRandom(list: any[]) {
    let randomIndex = Math.floor((Math.random() * list.length) );
    return list[randomIndex];
  }
  changeAndReset(place:number) {
    for (var i=0; i<this.currentState.length; i++){
      if (i==place)
        this.currentState[i]='final'
      else
        this.currentState[i]='initial'
    }
    this.titleSequence(5)
  }
  changeLine() {
    if(this.breakpoint==9){
      this.lineState='initial'
      this.delay(1500).then(any=>{
        this.quote=this.getRandom(this.quotes)
        this.lineState='final'
      })
      this.titleSequence(20)
    }
  }
  changeTitle(i:number) {
    if (i<9){
      this.titleState[i]='final'
      this.delay(200).then(any=>{
          this.changeTitle(i+1)
          this.delay(500).then(any=>{
            this.titleState[i]='initial'
          })
      })
    }
  }
  changeLogo(){
    if (this.logoState=='initial')
      this.logoState='final'
    else
      this.logoState='initial'
  }
  titleSequence(i:number){
    for (var j=0; j<i; j++){
      this.overload=this.overload+1
      if (this.overload==200){
        this.overload=0
        this.changeLogo()
        this.delay(60).then(any=>{
          this.changeLogo()
          this.delay(50).then(any=>{
            this.changeLogo()
            this.delay(70).then(any=>{
              this.changeLogo()
              this.delay(30).then(any=>{
                this.changeLogo()
                this.delay(40).then(any=>{
                  this.changeLogo()
                  this.delay(150).then(any=>{
                    this.changeLogo()
                    if (this.breakpoint==9)
                     this.changeTitle(0)
                    this.delay(2700).then(any=>{
                      this.changeLogo()
                    })
                  })
                })
              })
            })
          })
        })
      }
    }
  }
  spinback() {
    if (this.backState=='initial')
      this.backState='final'
    else
      this.backState='initial'
    this.titleSequence(5)
  }
}
