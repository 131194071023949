import { Component, OnInit } from '@angular/core';
import { PayPalConfig, PayPalEnvironment, PayPalIntegrationType } from 'ngx-paypal';

@Component({
  selector: 'app-mailinglist',
  templateUrl: './mailinglist.component.html',
  styleUrls: ['../app.component.css']
})
export class MailinglistComponent implements OnInit {
  public payPalConfig?: PayPalConfig;

  constructor() { }

  ngOnInit() {
    this.initConfig();
  }
  private initConfig(): void {
    this.payPalConfig = new PayPalConfig(PayPalIntegrationType.ClientSideREST, PayPalEnvironment.Production, {
      commit: true,
      client: {
        production: 'AbGQyhvpI7sad6iXlTX468hWzG1WL4AHDqlBdlVVf1qbL4J9_9Jpx9sCiV0k9b6hGuhfU_X-gcxNfYI6',
      },
      button: {
        label: 'paypal',
      },
      onPaymentComplete: (data, actions) => {
        console.log('OnPaymentComplete');
      },
      onCancel: (data, actions) => {
        console.log('OnCancel');
      },
      onError: (err) => {
        console.log('OnError');
      },
      transactions: [{
        amount: {
          currency: 'USD',
          total: 9
        }
      }]
    });
  }

}
